import './Home.scss';
import * as React from 'react';
import { useRef } from 'react';
import { useAppDispatch } from '../../feature/hooks';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Grid from '@mui/material/Grid';
import { setIsFormOpen } from '../../feature/formSlice';
import FormHelperText from '@mui/material/FormHelperText';
import axios from 'axios'
import {BsFillPatchCheckFill,BsFillExclamationCircleFill} from "react-icons/bs"
const SignUp = () => {
    const [showPassword, setShowPassword] = React.useState(false);
    const modalRef = useRef(null);
    const dispatch = useAppDispatch();
    const [formStatus,setFormStatus] = React.useState("");
    const [formData, setFormData] = React.useState({
        fullName: "",
        email: "",
        phone: "",
        gender: "",
        password: "",
        confirmPassword: "",
        terms: false
    });

    const [errors, setErrors] = React.useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const handleCheckboxChange = (e) => {
        setFormData(prevState => ({
            ...prevState,
            terms: e.target.checked
        }));
    }
    //validate signup form
    const validate = () => {
        let tempErrors = {};

        if (!formData.fullName) tempErrors.fullName = "Full Name is required";
        else if (/[\d]/.test(formData.fullName)) tempErrors.fullName = "Full Name should not contain numbers";

        if (!formData.email) tempErrors.email = "Email is required";
        else if (!/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(formData.email)) tempErrors.email = "Email is not valid";

        if (!formData.phone) tempErrors.phone = "Phone Number is required";
        else if (formData.phone.length !== 10) tempErrors.phone = "Phone Number should be 10 digits";

        if (!formData.password) tempErrors.password = "Password is required";
        if (!formData.confirmPassword) tempErrors.confirmPassword = "Confirm Password is required";
        else if (formData.password !== formData.confirmPassword) tempErrors.confirmPassword = "Passwords do not match";

        if (!formData.gender) tempErrors.gender = "Please select a gender";
       
        if (!formData.terms) tempErrors.terms = "You must accept the terms and conditions";

        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            //using axios to send POST req
            axios(
                {
                    url: "https://server.zerito.in/auth/signUp",
                    method: "POST",
                    data:formData
                }
            ).then((res) => {
                 
                    //handle successfull user creation
                    // ask user to check their email to confirm
                    setFormStatus("Success")
                    setFormData({
                        fullName: "",
                        email: "",
                        phone: "",
                        gender: "",
                        password: "",
                        confirmPassword: "",
                        terms: false
                    })
                    setTimeout(() => {
                        dispatch(setIsFormOpen(false));
                    }, 5000);
            }).catch((err) => {
                if (err.response.status == 401) {
                    //show user already exists message and ask to download the app to continue
                    setErrors({email:"an user already exists with the current email"})
                } else {
                    //show server error message
                    setFormStatus("Error")
                    setFormData({
                        fullName: "",
                        email: "",
                        phone: "",
                        gender: "",
                        password: "",
                        confirmPassword: "",
                        terms: false
                    })
                    setTimeout(() => {
                        dispatch(setIsFormOpen(false));
                    }, 5000);
                
                }
            })
            
        }
        
    }

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = event => {
        event.preventDefault();
    };

    React.useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                dispatch(setIsFormOpen(false));
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    return (
        <div className='modal-overlay'>
            {!formStatus ?
            <div className="modal" ref={modalRef}>
                <h3>Sign up now!</h3>
                <div className='form-container'>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} lg={12} className='item'>
                            <TextField
                                variant='outlined'
                                label="Full Name"
                                className='fullName'
                                fullWidth
                                name="fullName"
                                value={formData.fullName}
                                onChange={handleChange}
                                helperText={errors.fullName}
                                error={Boolean(errors.fullName)}
                                autoComplete='off'
                                sx={{
                                    '& .MuiInputLabel-root': {
                                      '@media (max-width:768px)': {
                                        transform: 'scale(0.75)'
                                      }
                                    }
                                  }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={6} md={6} lg={6} className='item'>
                            <TextField
                                variant='outlined'
                                label="Email id"
                                className='email'
                                type='email'
                                fullWidth
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                helperText={errors.email}
                                error={Boolean(errors.email)}
                                autoComplete='off'
                                sx={{
                                    '& .MuiInputLabel-root': {
                                      '@media (max-width:768px)': {
                                        transform: 'scale(0.75)'
                                      }
                                    }
                                  }}
                            />
                        </Grid>
                        <Grid item xs={6} md={6} lg={6} className='item'>
                            <TextField
                                variant='outlined'
                                label="Phone no."
                                className='phone'
                                type='tel'
                                fullWidth
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                                helperText={errors.phone}
                                error={Boolean(errors.phone)}
                                autoComplete='off'
                                sx={{
                                    '& .MuiInputLabel-root': {
                                      '@media (max-width:768px)': {
                                        transform: 'scale(0.75)'
                                      }
                                    }
                                  }}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} lg={12} className='item' style={{ paddingTop: "0.5vw" }}>
                            <FormControl className='gender' error={Boolean(errors.gender)}>
                                <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="gender"
                                    value={formData.gender}
                                    onChange={handleChange}
                                >
                                    <FormControlLabel value="female" control={<Radio />} label="Male" />
                                    <FormControlLabel value="male" control={<Radio />} label="Female" />
                                    <FormControlLabel value="other" control={<Radio />} label="Others" />
                                </RadioGroup>
                                {errors.gender && <FormHelperText>{errors.gender}</FormHelperText>}
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className='pswd'>
                        <Grid item md={6} className='item' style={{ paddingTop: "0vw" }}>
                            <FormControl sx={{ m: 1 }} variant="outlined" fullWidth className='pswdControl' error={Boolean(errors.password)}>
                                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    type={showPassword ? 'text' : 'password'}
                                    name="password"
                                    value={formData.password}
                                    onChange={handleChange}
                                    sx={{
                                        '& .MuiInputLabel-root': {
                                          '@media (max-width:768px)': {
                                            transform: 'scale(0.75)'
                                          }
                                        }
                                      }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Password"
                                />
                                {errors.password && <FormHelperText>{errors.password}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item md={6} className='item' style={{ paddingTop: "0.6vw" }}>
                            <TextField
                                id="outlined-password-input"
                                label="Confirm Password"
                                type="password"
                                fullWidth
                                className='confirmPswd'
                                name="confirmPassword"
                                sx={{
                                    '& .MuiInputLabel-root': {
                                      '@media (max-width:768px)': {
                                        transform: 'scale(0.75)'
                                      }
                                    }
                                  }}
                                value={formData.confirmPassword}
                                onChange={handleChange}
                                helperText={errors.confirmPassword}
                                error={Boolean(errors.confirmPassword)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item md={12} className='item'>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={formData.terms}
                                        onChange={handleCheckboxChange}
                                        name="terms"
                                        />}
                                    label={<span className="terms">
                                        <a href="/policies/terms-and-service"
                                        target="_blank" 
                                        rel="noopener noreferrer"
                                        onClick={(e) => {
                                            // Check if the target is the link
                                            if (e.target.tagName !== 'A') {
                                                // Toggle checkbox if the link wasn't clicked
                                                handleCheckboxChange(e);
                                            }
                                        }}>
                                        Terms & conditions
                                        </a>
                                        </span>}
                                />
                                {errors.terms && <FormHelperText error>{errors.terms}</FormHelperText>}
                            </FormGroup>
                        </Grid>
                    </Grid>


                    <button className='signup' onClick={handleSubmit}>SIGN UP</button>
                </div>
            </div>
            :
            <div className="modal" ref={modalRef}>
                {formStatus === "Success" &&
                <div className='formStatus'>
                    <BsFillPatchCheckFill/>
                    <h3 className='message'>Confirmation mail sent successfully!!</h3>
                </div>}
                {formStatus === "Error" &&
                <div className='formStatus'>
                    <BsFillExclamationCircleFill className="error"/>
                    <h3 className='message'>Error submitting form!!</h3>
                </div>}
            </div>
        }
        </div>
    );
}



export default SignUp;