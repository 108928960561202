import { createSlice } from "@reduxjs/toolkit";

const isFormSlice = createSlice({
  name: "formState",
  initialState: false,
  reducers: {
    setIsFormOpen(state, action) {
      return action.payload;
    }
  },
});

export const { setIsFormOpen} = isFormSlice.actions;
export default isFormSlice.reducer;