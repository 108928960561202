import './Privacy.scss';
import { useEffect } from 'react';
function Privacy(){
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    return (
        <div className="privacyWrap">
            <div className="content">
                <h3>Privacy Policy</h3>
                <p>At Zerito, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, and disclose your data. By accessing or using the Zerito platform, you consent to the practices described in this policy.</p>
                <ul className='policies'>
                    <li className='points'><span>Information Collection : </span>We collect personal information when you register for an account, list a skill, make a purchase, or communicate with us. The information we collect may include your name, contact details, payment information, and other relevant data.
                    </li>
                    <li className='points'><span>Use of Information : </span>
                    We use the collected information to provide and improve our services, facilitate transactions, communicate with you, personalize your experience, and comply with legal obligations. 
                    We may also use the information for research, analytics, and marketing purposes.
                    </li>
                    <li className='points'><span>Data Sharing : </span>Zerito may share your personal information with skilled professionals or buyers involved in a transaction to facilitate the completion of services. We may also share your information with trusted third-party service providers who assist us in operating our platform. We do not sell or rent your personal information to third parties.
                    </li>
                    <li className='points'><span>Data Security : </span>We implement security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no data transmission over the internet can be guaranteed as 100% secure. Therefore, we cannot ensure or warrant the absolute security of your information.
                    </li>
                    <li className='points'><span>Cookies : </span>Zerito uses cookies and similar technologies to enhance your browsing experience and collect information about your usage patterns. You can manage your cookie preferences through your browser settings.</li>
                    <li className='points'><span>Third-Party Links : </span>Our platform may contain links to third-party websites or services. We are not responsible for the privacy practices or content of these third-party sites. We encourage you to review their privacy policies before providing any personal information.
                    </li>
                    <li className='points'><span>Children's Privacy : </span>Zerito does not knowingly collect personal information from individuals under the age of 18. If we become aware that we have collected personal data from a child without parental consent, we will take steps to delete that information.
                    </li>
                    <li className='points'><span>Your Rights : </span>You have the right to access, correct, and delete your personal information. You may also have the right to restrict or object to certain processing activities. To exercise your rights or seek clarification, please contact us using the information provided below.
                    </li>
                    <li className='points'><span>Changes to the Policy : </span> Zerito reserves the right to modify or update this Privacy Policy as needed. Any changes will be effective upon posting on our platform. We encourage you to review this policy periodically for any updates.
                    </li>
                </ul>
                <p>If you have any questions or concerns about our Terms and Conditions or Privacy Policy, please contact us at <span>admin@zerito.in</span></p>
            </div>
        </div>
    );
}
export default Privacy;