import './Home.scss';
import { FaPhone } from 'react-icons/fa';
import { BsArrowRight } from 'react-icons/bs'
import React, { useState, useEffect, useRef } from 'react';
import homeFooter from './../../Assets/home-footer.jpg';
import pp2 from './../../Assets/pp2.jpg';
import ChildSection from './ChildSection';
import ChildComponent from './ChildComponent';
import SignUp from './SignUp';
import Footer from './Footer';
import OurService from './OurServices';
import ContactForm from './ContactForm';
import { Element, Link } from 'react-scroll';
import Articles from './Articles';
import { useAppSelector,useAppDispatch } from '../../feature/hooks';
import { setIsFormOpen } from '../../feature/formSlice';

function Home() {
  const homeFooterRef = useRef(null);
  const dispatch = useAppDispatch();
  const isFormCurrentlyOpen = useAppSelector((state) => state.isFormOpen);

  const openFormHandler = () => {
    dispatch(setIsFormOpen(true));
  }
  useEffect(() => {
    // Function to handle scroll event
    const handleScroll = () => {
      const homeFooterElement = homeFooterRef.current;

      if (homeFooterElement && isElementInViewport(homeFooterElement)) {
        homeFooterElement.classList.add('fade-in-left');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Helper function to check if an element is in the viewport
  const isElementInViewport = (element) => {
    const rect = element.getBoundingClientRect();
    return (
      rect.left >= 0 &&
      rect.top >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
    );
  };
  return (
    <div className='app-content'>
      <Element name="home">
      <div className="app-home-wrapper">
        <div className='top-header-content'>
          <h3>ZERITO</h3>
          <p className='header-content-3'>Discover the Power of AI-Driven HealthCare</p>
          <div className='top-header-actions'>
            <button className='header-action-btn signup' onClick={openFormHandler}>JOIN NOW <BsArrowRight /></button>

            {/* <Link
              activeClass="active"
              to="contactForm"
              spy={true}
              smooth={true}
              offset={-30}
              duration={500} style={{ marginLeft: "0vw" }}><button className='header-action-btn contact'><span>CONTACT US <FaPhone /></span><span></span></button></Link> */}
          </div>
        </div>
        <img src={pp2} className='header-video-player'></img>
        <Element name="aboutUs">
          <div className='home-footer-wrapper'>
            <div className='home-footer-content'>
              {/* <img src={homeFooter} ref={homeFooterRef}></img> */}
              <div className='footer-right-content'>
                <h3>ABOUT US</h3>
                <p className='para-one'>Elevate Your Healthcare Experience with Our AI-Powered App. Connect with the Best Professionals, Empowered by Cutting-Edge Technology.</p>
                <p className='para-two'>Our advanced AI model analyzes your mental and physical health to provide a comprehensive report. Based on this report we will provide the best support for you.</p>
              
              </div>
            </div>
          </div>
        </Element>
        {isFormCurrentlyOpen && <SignUp />}
      </div>
      </Element>
     
      {/* <ChildSection /> */}
      {/* <ChildComponent /> */}
      <OurService />
      {/* <Articles /> */}
      <Element name="contactForm">
        <ContactForm />
      </Element>

      <Footer />
    </div>
  );
}
export default Home;