import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import TopNav from './Components/TopNav/TopNav';
import reportWebVitals from './reportWebVitals';
import Home from './Components/Home/Home';
import Privacy from './Components/Policies/Privacy';
import Terms from './Components/Policies/Terms';
import { Provider } from "react-redux";
import { store } from "../src/feature/store";
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import DeleteAccount from './Components/Policies/DeleteAccount';
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Router>
      <TopNav />
      <Provider store={store}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/policies/privacy" element={<Privacy />} />
          <Route path="/policies/terms-and-service" element={<Terms />} />
          <Route path='/delete-account' element={<DeleteAccount />} />
        </Routes>
      </Provider>
    </Router>


  </React.StrictMode>
);


reportWebVitals();
