import './Home.scss';
import React from 'react';
import TextField from '@mui/material/TextField';
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import axios from 'axios';
import {BsFillPatchCheckFill,BsFillExclamationCircleFill} from "react-icons/bs"
const ContactForm = () => {
    const [formData, setFormData] = React.useState({
        firstName: '',
        lastName: '',
        email: '',
        feedback: ''
    });

    const [errors, setErrors] = React.useState({});
    const [formStatus,setFormStatus] = React.useState("");
    //validate contact form
    const validateForm = () => {
        const newErrors = {};

        if (!formData.firstName || /\d/.test(formData.firstName)) {
            newErrors.firstName = 'Please enter a valid first name';
        }
        if (!formData.lastName || /\d/.test(formData.lastName)) {
            newErrors.lastName = 'Please enter a valid last name';
        }
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        if (!formData.email || !emailRegex.test(formData.email)) {
            newErrors.email = 'Please enter a valid email';
        }
        if (!formData.feedback) {
            newErrors.feedback = 'Feedback is required';
        }

        setErrors(newErrors);
        if (Object.keys(newErrors).length > 0) {
            clearErrorsAfterDelay();
        }
        return Object.keys(newErrors).length === 0;
    }
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    }
    const handleSubmit = (event) => {
        event.preventDefault();

        if (validateForm()) {
           //handle contact form submission here. (service call)
            axios({
                
                    url: "https://server.zerito.in/contactUs/message",
                    method: "POST",
                    data:formData
                
            }).then(() => {
                //todo show success dialog
                //todo disable spamming of submit button
                setFormStatus("Success")
                setFormData({
                    firstName: '',
                    lastName: '',
                    email: '',
                    feedback: ''
                })
                setTimeout(() => {
                    setFormStatus("")
                }, 15000);
                
            }).catch((err) => {
            
                //todo show server error dialog
                setFormStatus("Error")
                setFormData({
                    firstName: '',
                    lastName: '',
                    email: '',
                    feedback: ''
                })
                setTimeout(() => {
                    setFormStatus("")
                }, 10000);
            })
            
        }
    }
    const clearErrorsAfterDelay =() => {
        const timer = setTimeout(() => {
            setErrors({});
        }, 10000);
    
        return timer;
    }
    return (
        <div className='contactForm'>
            <div className='center-content'>
                <div className='left-content'>
                    <h3>CONTACT US</h3>
                    <h2>Let's Make It Happen </h2>
                    <div className='contactWrap'>
                        <div className='email'>
                            <div className='circle'><AiOutlineMail /></div>
                            <div className='info'>
                                <h3>Email</h3>
                                <h4>admin@zerito.in</h4>
                            </div>
                        </div>
                        <div className='phone'>
                            <div className='circle'><AiOutlinePhone /></div>
                            <div className='info'>
                                <h3>Phone</h3>
                                <h4>7306881678</h4>
                            </div>
                        </div>
                    </div>
                </div>
                {!formStatus ?
                <div className='form-wrapper'>
                    <div className='nameField'>
                        <TextField
                            variant='outlined'
                            label="First Name"
                            className='firstName'
                            sx={{
                                '& .MuiInputLabel-root': {
                                  '@media (max-width:768px)': {
                                    transform: 'scale(0.75)'
                                  }
                                }
                              }}
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                            error={!!errors.firstName}
                            helperText={errors.firstName}
                            autoComplete='off'
                        />
                        <TextField
                            variant='outlined'
                            label="Last  Name"
                            sx={{
                                '& .MuiInputLabel-root': {
                                  '@media (max-width:768px)': {
                                    transform: 'scale(0.75)'
                                  }
                                }
                              }}
                            className='lastName'
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                            error={!!errors.lastName}
                            helperText={errors.lastName}
                            autoComplete='off'
                        />
                    </div>
                    <TextField
                        variant='outlined'
                        label="Email"
                        className='email'
                        sx={{
                            '& .MuiInputLabel-root': {
                              '@media (max-width:768px)': {
                                transform: 'scale(0.75)'
                              }
                            }
                          }}
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        error={!!errors.email}
                        helperText={errors.email}
                        autoComplete='off'
                        style={{marginTop:"0.7vw"}}
                    />
                    <TextField
                        multiline
                        variant='outlined'
                        sx={{
                            '& .MuiInputLabel-root': {
                              '@media (max-width:768px)': {
                                transform: 'scale(0.75)'
                              }
                            }
                          }}
                        className='feedback'
                        autoComplete='off'
                        name="feedback"
                        value={formData.feedback}
                        onChange={handleChange}
                        error={!!errors.feedback}
                        helperText={errors.feedback}
                    />
                    <button className='send' onClick={handleSubmit}>SEND MESSAGE</button>
                </div>
                : 
                <div className='form-wrapper'>
                    {formStatus === "Success" &&
                <div className='formStatus'>
                    <BsFillPatchCheckFill/>
                    <h3 className='message'>Feedback submitted successfully!!</h3>
                </div>}
                {formStatus === "Error" &&
                <div className='formStatus'>
                    <BsFillExclamationCircleFill className="error"/>
                    <h3 className='message'>Error submitting feedback!!</h3>
                </div>}
                </div>
                }
            </div>
        </div>
    );
}

export default ContactForm;