import './Privacy.scss';
import Grid from '@mui/material/Grid';
import { useEffect } from 'react';
import * as React from 'react';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import {BsFillPatchCheckFill,BsFillExclamationCircleFill} from "react-icons/bs"
function DeleteAccount() {
    const [formStatus,setFormStatus] = React.useState("");
    const [formData, setFormData] = React.useState({
        email: "",
        password: "",
        confirmPassword: ""
    });
    const [errors, setErrors] = React.useState({});
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }
      //validate signup form
      const validate = () => {
        let tempErrors = {};

        if (!formData.email) tempErrors.email = "Email is required";
        else if (!/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(formData.email)) tempErrors.email = "Email is not valid";

        if (!formData.password) tempErrors.password = "Password is required";
        if (!formData.confirmPassword) tempErrors.confirmPassword = "Confirm Password is required";
        else if (formData.password !== formData.confirmPassword) tempErrors.confirmPassword = "Passwords do not match";

        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    }
    const handleDelete = (e) => {
        e.preventDefault();
        if (validate()) {
            //handle delete form submission here.  (service call)
            axios({
                
                url: "https://server.zerito.in/user/deleteAccountByEmail",
                method: "DELETE",
                data:formData
            
        }).then(() => {
            //todo show success dialog
            //todo disable spamming of submit button
            setFormStatus("Success")
            setFormData({
                email: '',
                password: '',
                confirmPassword: ''
            })
            setTimeout(() => {
                setFormStatus("")
            }, 10000);
            
        }).catch((err) => {
        
            //todo show server error dialog
            setFormStatus("Error")
            setFormData({
                email: '',
                password: '',
                confirmPassword: ''
            })
            setTimeout(() => {
                setFormStatus("")
            }, 10000);
        })

           
            
        }
        
    }
    return (
        <div className="privacyWrap delWrapper">
            <div className="content delContent">
            {!formStatus ?
                <div className="modal">
                    <h3 className='title'>Permanently delete account</h3>
                    <div className='form-container'>
                        <Grid container spacing={2}>
                        <Grid item xs={12} md={12} lg={12} className='item'>
                            <TextField
                                variant='outlined'
                                label="Email id"
                                className='email'
                                type='email'
                                fullWidth
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                helperText={errors.email}
                                error={Boolean(errors.email)}
                                autoComplete='off'
                                sx={{
                                    '& .MuiInputLabel-root': {
                                      '@media (max-width:768px)': {
                                        transform: 'scale(0.75)'
                                      }
                                    }
                                  }}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} lg={12} className='item' style={{ paddingTop: "0.6vw", marginBottom: "1.3vw" }}>
                            <TextField
                                id="outlined-password-input"
                                label="Password"
                                type="password"
                                fullWidth
                                className='confirmPswd'
                                name="password"
                                sx={{
                                    '& .MuiInputLabel-root': {
                                      '@media (max-width:768px)': {
                                        transform: 'scale(0.75)'
                                      }
                                    }
                                  }}
                                value={formData.password}
                                onChange={handleChange}
                                helperText={errors.password}
                                error={Boolean(errors.password)}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} lg={12} className='item' style={{ paddingTop: "0.6vw" }}>
                            <TextField
                                id="outlined-password-input"
                                label="Confirm Password"
                                type="password"
                                fullWidth
                                className='confirmPswd'
                                name="confirmPassword"
                                sx={{
                                    '& .MuiInputLabel-root': {
                                      '@media (max-width:768px)': {
                                        transform: 'scale(0.75)'
                                      }
                                    }
                                  }}
                                value={formData.confirmPassword}
                                onChange={handleChange}
                                helperText={errors.confirmPassword}
                                error={Boolean(errors.confirmPassword)}
                            />
                        </Grid>
                        </Grid>
                        <button className='delBtn' onClick={handleDelete}>DELETE ACCOUNT</button>
                    </div>
                </div>
                :
                <div className="modal status" >
                    {formStatus === "Success" &&
                <div className='formStatus'>
                    <BsFillPatchCheckFill/>
                    <h3 className='message'>Deleted user account successfully!!</h3>
                </div>}
                {formStatus === "Error" &&
                <div className='formStatus'>
                    <BsFillExclamationCircleFill className="error"/>
                    <h3 className='message'>Error deleting account!!</h3>
                </div>}
                </div>
}
            </div>
        </div>
            );
}
export default DeleteAccount;