import './Privacy.scss';
import { useEffect } from 'react';
function Terms(){
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="privacyWrap">
            <div className="content">
                <h3>Terms and Conditions</h3>
                <ul className='policies'>
                    <li className='points'><span>Acceptance of Terms : </span>By accessing or using the Zerito platform, you agree to be bound by these Terms and Conditions. If you do not agree with any part of these terms, please refrain from using our services.
                    </li>
                    <li className='points'><span>User Eligibility : </span>
                    Zerito is available to individuals who are at least 18 years old and capable of entering into a legally binding agreement. By using our platform, you confirm that you meet these eligibility requirements.
                    </li>
                    <li className='points'><span>Account Creation : </span>In order to use Zerito, you must create a user account. You are responsible for providing accurate and up-to-date information during the registration process. You agree to maintain the confidentiality of your account credentials and are solely responsible for any activity that occurs under your account.
                    </li>
                    <li className='points'><span>Skill Listings and Transactions : </span>Zerito allows users to buy and sell skills, with the ability to set their own terms and prices. By listing a skill or purchasing a skill, you agree to abide by the agreed-upon terms and conditions of the transaction. Zerito does not assume any responsibility for the quality of services provided by skilled professionals or the conduct of users.
                    </li>
                    <li className='points'><span>Payments : </span>Zerito facilitates payments between buyers and sellers. By using our platform, you authorize Zerito to collect and process payments on your behalf. We utilize secure payment gateways to ensure the confidentiality and integrity of your financial information.</li>
                    <li className='points'><span>User Conduct : </span>Users of Zerito must adhere to ethical and legal standards. You agree not to engage in any activity that violates applicable laws, infringes upon the rights of others, or disrupts the proper functioning of the platform. Zerito reserves the right to suspend or terminate accounts of users who violate these terms.
                    </li>
                    <li className='points'><span>Intellectual Property:  </span>Zerito retains all rights, titles, and interests in the platform, including but not limited to trademarks, copyrights, and proprietary information. Users are prohibited from using, reproducing, or distributing any content from the platform without prior written consent from Zerito.
                    </li>
                    <li className='points'><span>Privacy : </span>Zerito values your privacy and is committed to protecting your personal information. Our Privacy Policy outlines how we collect, use, and disclose your data. By using our platform, you agree to the terms of our Privacy Policy.
                    </li>
                    <li className='points'><span>Limitation of Liability : </span>Zerito shall not be held liable for any direct, indirect, incidental, consequential, or punitive damages arising from the use of our platform. We make no warranties or representations regarding the accuracy, reliability, or availability of the services provided.
                    </li>
                    <li className='points'><span>Modification of Terms : </span>Zerito reserves the right to modify or update these Terms and Conditions at any time. Any changes will be effective upon posting on our platform. Continued use of Zerito after the modifications constitute acceptance of the updated terms.
                    </li>
                </ul>
            </div>
        </div>
    );
}
export default Terms;