import { configureStore } from "@reduxjs/toolkit";
import isFormOpenReducer from "./formSlice"
export const store = configureStore({
    reducer: {
        isFormOpen: isFormOpenReducer
    }
})


export const RootState = store.getState;

export const AppDispatch = store.dispatch;