import './Home.scss';
import React from 'react';
import { BsArrowRight } from 'react-icons/bs';
import { FaPhone } from 'react-icons/fa';
import { AiFillInstagram, AiFillFacebook, AiOutlineTwitter, AiFillLinkedin } from 'react-icons/ai'
import zeritoLogo from '../../Assets/zerito yellow.svg'
import playStoreBtn from '../../Assets/playStoreBtn.png'
import { useAppDispatch } from '../../feature/hooks';
import { setIsFormOpen } from '../../feature/formSlice';
//import { Link } from 'react-scroll';
import { Link  as GoTo } from 'react-router-dom';
const Footer = () => {
    const dispatch = useAppDispatch();

    const openFormHandler = () => {
        dispatch(setIsFormOpen(true));
    }
    return (
        <div className='footerComponent'>
            <div className='content-wrapper'>
                <h4>JOIN NOW</h4>
                <div className='flexDiv'>
                    <h2>Discover the Power of AI-Driven HealthCare</h2>
                    <div className='actionBtns'>
                        <button className='actionBtn signup' onClick={openFormHandler}>JOIN NOW <BsArrowRight /></button>
                        {/* <Link
                            activeClass="active"
                            to="contactForm"
                            spy={true}
                            smooth={true}
                            offset={-30}
                            duration={500} style={{ marginLeft: "0vw" }}><button className='actionBtn contact'><span>CONTACT US <FaPhone /></span><span></span></button></Link> */}
                    </div>
                </div>
                <div className='ftrSocial'>
                    <div className='logoContainer'>
                        <img src={zeritoLogo}></img>
                        <h3>SOCIAL LINKS</h3>
                        <div className='socialMedia'>
                            <div className='icon fbicon' onClick={() => window.open("https://www.facebook.com/profile.php?id=100094327730260")}>
                                <AiFillFacebook />
                            </div>
                            <div className='icon'  onClick={() => window.open("https://www.instagram.com/zerito.in/")}>
                                <AiFillInstagram />
                            </div>
                            <div className='icon'  onClick={() => window.open("https://x.com/zerito_in?t=OW3KHshJr3Ad33dFnrLY0Q&s=08")}>
                                <AiOutlineTwitter />
                            </div>
                            <div className='icon'  onClick={() => window.open("https://www.linkedin.com/company/zerito-in/")}>
                                <AiFillLinkedin />
                            </div>
                        </div>
                    </div>
                    <div className='downloadBtn'>

                        <img src={playStoreBtn} className='playStore'></img>
                    </div>
                </div>
            </div>
            <div className='footer'>
                <div className='content'>
                    <h3>Copyright &copy; 2023 Zerito</h3>
                    <div>
                    <GoTo to="/delete-account"><h3 style={{ marginRight: "1vw" }}>Delete Account</h3></GoTo>
                    <GoTo to="/policies/privacy" ><h3 style={{ marginRight: "1vw" }}>Privacy Policy</h3></GoTo>
                        <GoTo to="/policies/terms-and-service" ><h3>Terms & Services</h3></GoTo>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;