import './Home.scss';
import React from 'react';
import imageService from './../../Assets/services.jpg'
const OurService = () => {
    return (
        <div className='service-wrap'>
            <div className='center-content'>
                <div className='section-two'>
                    <h3>OUR SERVICES</h3>
                    <div className='one'>
                        <span><h1>01</h1></span>
                        <div>
                            <h2>Mental Health Counseling</h2>
                            <p>Individual counseling, Couples therapy, Addiction counseling, Stress management, Depression and anxiety treatment </p>
                        </div>
                    </div>
                    <div className='two'>
                    <span><h1>02</h1></span>
                        <div>
                            <h2>Physiotherapy</h2>
                            <p>Musculoskeletal, Neurological, Cardiorespiratory,Pediatric, Geriatric, Sports</p>
                        </div>
                    </div>
                    {/* <div className='three'>
                    <span><h1>03</h1></span>
                        <div>
                            <h2>Others</h2>
                            <p>Designer,Teacher,Chef,Driver,Mechanic,etc..</p>
                        </div>
                    </div> */}
                </div>
                <div className='section-three'>
                    <img src={imageService}></img>
                </div>
            </div>
        </div>
    );
}

export default OurService;