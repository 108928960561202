import zeritoLogo from '../../Assets/zerito yellow.svg'
import React, { useState} from 'react';
import { GiHamburgerMenu } from 'react-icons/gi'
import { Link } from 'react-scroll';
import { useLocation } from "react-router-dom";
import './TopNav.css';
import { Link as GoTo } from 'react-router-dom';
function TopNav() {
  const [activeLink, setActiveLink] = useState('HOME');
  const [isMenuOpen, setMenuOpen] = useState(false);
  const location = useLocation();
  
  const isHome = location.pathname === "/";
  const handleClick = (e) => {
    setActiveLink(e.target.innerText);
  };
  const handleMenuToggle = () => {
    setMenuOpen(!isMenuOpen);
  };
  return (
    <div className="app-top-nav-wrapper">
      <div className='logo'>
        <img src={zeritoLogo} onClick={() => window.location.href = '/'}></img>
      </div>
      <GiHamburgerMenu className='menu-icon' onClick={handleMenuToggle} />
      <div className={`links ${isMenuOpen ? 'open' : ''}`}>
        {!isHome &&
        <GoTo to="/">   
            <a
              href="#link1"
              className={activeLink === 'HOME' ? 'active' : ''}
              onClick={handleClick}>HOME</a>
        </GoTo>}
        {isHome &&
          <Link
            activeClass="active"
            to="aboutUs"
            spy={true}
            smooth={true}
            offset={-30}
            duration={500} style={{ marginLeft: "0vw" }}>
            <a
              href="#link2"
              className={activeLink === 'ABOUT' ? 'active' : ''}
              onClick={handleClick}>ABOUT</a>
          </Link>}
        {isHome &&
          <Link
            activeClass="active"
            to="contactForm"
            spy={true}
            smooth={true}
            offset={-30}
            duration={500} style={{ marginLeft: "0vw" }}>
            <a
              href="#link3"
              className={activeLink === 'CONTACT' ? 'active' : ''}
              onClick={handleClick}>CONTACT</a>
          </Link>}

      </div>
    </div>
  );
}

export default TopNav;
